import React, { useState } from 'react';
import { Container, Row, Col, Image,Modal, Button  } from 'react-bootstrap';
import '../css/gallery.css';
import NavBar from './NavBar';
import Footer from './Footer';

const GalleryFacial = () => {
  const [show, setShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState('/1.jpg');

    const images = [
    
   
      {
        title: 'Image 1',
        description: 'Description for Image 1',
        src: '/1-720x900.jpg'
      },
      {
        title: 'Image 2',
        description: 'Description for Image 2',
        src: '/2-720x900.jpg'
      },
      {
        title: 'Image 3',
        description: 'Description for Image 3',
        src: '/3-720x900.jpg'
      },
      {
        title: 'Image 4',
        description: 'Description for Image 4',
        src: '/4-720x900.jpg'
      },
      
    ];
  
  const handleShow = (image) => {
    setSelectedImage(image.src);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handlePrev = () => {
    const index = images.findIndex((image) => image.src === selectedImage);
    setSelectedImage(images[(index + images.length - 1) % images.length].src);
  };

  const handleNext = () => {
    const index = images.findIndex((image) => image.src === selectedImage);
    setSelectedImage(images[(index + 1) % images.length].src);
  };
  return (
    
    <div fluid style={{ backgroundColor: '#fff5e9' }} >
      <NavBar   className="sticky-top" sticky="top" style={{ position: "sticky"}}></NavBar>
<Container style={{ marginTop:'20px'}}>
<Row>
       {images.map((image) => (
         <Col key={image.src} lg={4}>
           <Image
             src={require(`../images/gallery/facial${image.src}`)}
             alt={image.alt}
             className="modal-content-image  img-fluid w-100"
             onClick={() => handleShow(image)}
             style={{width:'100%'}}
           />
         </Col>
       ))}
     </Row>
    
     <Modal show={show} onHide={handleClose} centered>
       <Modal.Body>
       <Image 
       src={require(`../images/gallery/facial${selectedImage}`)}
           alt="" className="w-100"
           style={{width:100}} 
           />
       </Modal.Body>
       <Modal.Footer>
         <Button variant="secondary" onClick={handlePrev}>
           Prev
         </Button>
         <Button variant="primary" onClick={handleNext}>
           Next
         </Button>
         <Button variant="danger" onClick={handleClose}>
           Close
         </Button>
       </Modal.Footer>
     </Modal>
</Container>
<Footer/>
     
   </div>
  );
};

export default GalleryFacial;
