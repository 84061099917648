import { Container } from 'react-bootstrap';
import HomePageComponent from '../components/HomePageComponent';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

function HomePage() {
  return (
    <div fuid="true"  style={{ backgroundColor: '#fff5e9' }} >
      <NavBar   className="sticky-top" sticky="top" style={{ position: "sticky"}}></NavBar>
      <HomePageComponent />
      <Footer/>
    </div >
  );
}

export default HomePage;