import { Container, Carousel, Card, CardGroup, Button, Image, Row, Col } from 'react-bootstrap';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import '../css/services.css';
function Services() {
    return (
        <div fluid style={{ backgroundColor: '#fff5e9' }}>
            <NavBar className="sticky-top" sticky="top" style={{ position: "sticky" }}></NavBar>
            {/* First Section of carousel */}
            {/* <Container fluid  style={{ padding: 0 }} >
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className="d-block w-100  opacity-50"
                            src={require("../images/carousel/service-carousel.jpg")}
                            alt="First slide"
                        // style={{  height:'400px' }}
                        />
                        <Carousel.Caption style={{ top: '50%', transform: 'translateY(-50%)', bottom: 'initial' }}>
                            <h1 style={{ fontFamily: 'fantasy', color: 'purple' }}>Our Services</h1>
                            {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
            {/* </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </Container> */}
            <Container fluid style={{ padding: 0, backgroundColor: 'cyan' }}  >
                <Carousel fade controls={false} indicators={false} >
                    <Carousel.Item>
                        <img
                            className="d-block w-100  "
                            src={require("../images/carousel/abtus-carousel.jpg")}
                            alt="First slide"

                        />
                        <Carousel.Caption style={{ top: '80%', transform: 'translateY(-50%)', bottom: 'initial' }}>

                            <h1
                                style={{ fontFamily: 'fantasy', color: "#ff3c5e" }}>
                                Services
                            </h1>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </Container>


            {/* Second Section - each with card and further details */}
            <div className="my-container" >


                <Container id='laser-container'>
                    <Row xs={1} md={2} className="my-card-deck">
                        <Col >
                            <CardGroup className="my-card-deck">
                                <Card className='custom-card' style={{ backgroundColor: 'mistyrose' }}>
                                    <Card.Img className='custom-round' variant="top" src={require("../images/cards/laser-hair-removal-face-768x512.jpg")} />
                                    <small className="text-muted text-center custom-service-footer">
                                        <Button className="override-button">Laser Hair Removal</Button> {' '}
                                    </small>
                                </Card>
                            </CardGroup>
                        </Col>
                        <Col style={{ padding: 'auto', margin: 'auto' }}>
                            <h1 className='text-center' style={{ color: 'black', fontFamily: 'vanitasblackreg' }}>Laser <span style={{ color: '#b89535' }}> Hair Removal</span> </h1>
                            <p className='laser-para'>
                                Long-term removal of undesirable hair has been a challenge for ages. People spend considerable time and money looking for ways to remove their unwanted hair such as waxing and shaving. Luckily, modern technology has designed a solution for this decades old concern.
                                With our Soprano ICE Platinum ( Trio clustered diode technology), any body part that has hair can be treated.
                            </p>
                            <p className='laser-para'>
                                Lips to legs, laser hair removal at <span style={{ color: '#C400FF' }}> Inner Beauty and Spa</span> can take care of any hair on your body with ease. Our laser can treat delicate areas (like Bikini and Brazilian), and gently eliminates your hairiest concerns.
                            </p>
                            <p className='laser-para'>

                                Please <span><a style={{ color: 'GrayText' }} href="/contactus">Contact Us</a></span> today for more information.
                            </p>

                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row xs={1} md={2} className="my-card-deck">

                        <Col className="item-image"  >
                            <CardGroup className="my-card-deck">
                                <Card className='custom-card' style={{ backgroundColor: 'mistyrose' }}>
                                    <Card.Img className='custom-round' variant="top" src={require("../images/cards/facial-768x512.jpg")} />
                                    <small className="text-muted text-center custom-service-footer">
                                        <Button className="override-button">Facial</Button> {' '}
                                    </small>
                                </Card>
                            </CardGroup>
                        </Col>
                        <Col className="item-text" style={{ padding: 'auto', margin: 'auto' }}>
                            <h1 className='text-center' style={{ color: 'black', fontFamily: 'vanitasblackreg' }}>Fac<span style={{ color: '#b89535' }}>ial</span> </h1>
                            <p className='laser-para'>
                                Indulge in our luxurious skincare services for a pampering experience.
                                Our expert estheticians use premium products to revitalize your skin, leaving it radiant.
                                Choose from treatments such as  Glow Facial, Micro-Needling, and many more.
                                Choose from our Alumier Glow Facial, Micro-Needling Treatment, and O3 Mask
                                starting at just $50. Give your skin the pampering it deserves and stop time.
                                You're worth it.      </p>
                            <p className='laser-para'>
                                At <span style={{ color: '#C400FF' }}> Inner Beauty and Spa</span>
                                , we provide a comprehensive range of world class facial services.
                            </p>
                            <p className='laser-para'>

                                Please <span><a style={{ color: 'GrayText' }} href="/contactus">Contact Us</a></span> today for more information.
                            </p>

                        </Col>
                    </Row>
                </Container>


                <Container>
                    <Row xs={1} md={2} className="my-card-deck">
                        <Col>
                            <CardGroup className="my-card-deck">
                                <Card className='custom-card' style={{ backgroundColor: 'mistyrose' }}>
                                    <Card.Img className='custom-round' variant="top" src={require("../images/cards/hair-treatment-768x512.jpg")} />
                                    <small className="text-muted text-center custom-service-footer">
                                        <Button className="override-button">Hair Care</Button> {' '}
                                    </small>
                                </Card>
                            </CardGroup>
                        </Col>
                        <Col style={{ padding: 'auto', margin: 'auto' }}>
                            <h1 className='text-center' style={{ color: 'black', fontFamily: 'vanitasblackreg' }}>Hair <span style={{ color: '#b89535' }}> Care</span> </h1>
                            <p className='laser-para'>
                                Whether you have fine or thick, straight or curly, color-treated or mature hair,
                                there's a vast marketplace of hair care products available to  help you achieve your
                                best hair day. From shampoos and conditioners to styling products and hair treatments,
                                you're sure to find the right products to nourish, protect, and enhance your hair.
                                With so many options out there, it's time to put your best mane forward!                   </p>
                            <p className='laser-para'>
                                At <span style={{ color: '#C400FF' }}> Inner Beauty and Spa</span>,
                                everything from a simple haircut to a gorgeous layered cut, hair spa treatment is available to you.
                            </p>
                            <p className='laser-para'>

                                Please <span><a style={{ color: 'GrayText' }} href="/contactus">Contact Us</a></span> today for more information.
                            </p>

                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row xs={1} md={2} className="my-card-deck">
                        <Col className="item-image" >
                            <CardGroup className="my-card-deck">
                                <Card className='custom-card' style={{ backgroundColor: 'mistyrose' }}>
                                    <Card.Img className='custom-round' variant="top" src={require("../images/cards/threading-768x512.jpg")} />
                                    <small className="text-muted text-center custom-service-footer">
                                        <Button className="override-button">Threading</Button> {' '}
                                    </small>
                                </Card>
                            </CardGroup>
                        </Col>
                        <Col style={{ padding: 'auto', margin: 'auto' }} className="item-text" >
                            <h1 className='text-center' style={{ color: 'black', fontFamily: 'vanitasblackreg' }}>Threa<span style={{ color: '#b89535' }}>ding</span> </h1>
                            <p className='laser-para'>
                                Say goodbye to painful waxing and hello to flawless threading at our salon.
                                Our threading services provide precise shaping and a natural finish in less time.
                                And if you want to take your brows to the next level, we also offer exceptional
                                eyebrow tinting to make them stand out.     </p>
                            <p className='laser-para'>
                                At <span style={{ color: '#C400FF' }}> Inner Beauty and Spa</span>
                                , we shape your brows using personalized threading keeping your facial structure in mind.
                                Come visit us and experience the difference!
                            </p>
                            <p className='laser-para'>

                                Please <span><a style={{ color: 'GrayText' }} href="/contactus">Contact Us</a></span> today for more information.
                            </p>

                        </Col>


                    </Row>
                </Container>


                <Container>
                    <Row xs={1} md={2} className="my-card-deck">
                        <Col>
                            <CardGroup className="my-card-deck">
                                <Card className='custom-card' style={{ backgroundColor: 'mistyrose' }}>
                                    <Card.Img className='custom-round' variant="top" src={require("../images/cards/bridal-makeup-768x512.jpg")} />
                                    <small className="text-muted text-center custom-service-footer">
                                        <Button className="override-button">Makeup</Button> {' '}
                                    </small>

                                </Card>
                            </CardGroup>
                        </Col>
                        <Col style={{ padding: 'auto', margin: 'auto' }}>
                            <h1 className='text-center' style={{ color: 'black', fontFamily: 'vanitasblackreg' }}>Make<span style={{ color: '#b89535' }}>up</span> </h1>
                            <p className='laser-para'>
                                Looking for flawless makeup? We've got you covered. Our makeup artists specialize
                                in giving you the perfect look for any occasion. Whether it's a natural finish or
                                a bold statement, we'll enhance your beauty with our professional techniques.
                                Visit us today at our salon and let us bring out your best features.                   </p>
                            <p className='laser-para'>
                                At <span style={{ color: '#C400FF' }}> Inner Beauty and Spa</span>,
                                Choose from range of makeup services, from a natural daytime look to a stunning evening glam.
                                we will make you feel confident for any occasion.
                            </p>
                            <p className='laser-para'>

                                Please <span><a style={{ color: 'GrayText' }} href="/contactus">Contact Us</a></span> today for more information.
                            </p>

                        </Col>
                    </Row>
                </Container>

                <Container >
                    <Row xs={1} md={2} className="my-card-deck" >
                        <Col className="item-image">
                            <CardGroup className="my-card-deck">
                                <Card className='custom-card' style={{ backgroundColor: 'mistyrose' }}>
                                    <Card.Img className='custom-round' variant="top" src={require("../images/cards/mehndi-design-768x512.jpg")} />
                                    <small className="text-muted text-center custom-service-footer">
                                        <Button className="override-button">Mehndi</Button> {' '}
                                    </small>
                                </Card>
                            </CardGroup>
                        </Col>
                        <Col style={{ padding: 'auto', margin: 'auto' }} className="item-text">
                            <h1 className='text-center' style={{ color: 'black', fontFamily: 'vanitasblackreg' }}>Meh<span style={{ color: '#b89535' }}>ndi</span> </h1>
                            <p className='laser-para'>
                                Add a touch of elegance to your hands with our exquisite Mehndi designs.
                                Our talented artists create intricate patterns that will leave you feeling
                                beautiful and confident. Whether it's for a wedding or a special occasion,
                                our henna services are sure to impress.
                            </p>
                            <p className='laser-para'>
                                At <span style={{ color: '#C400FF' }}> Inner Beauty and Spa</span>
                                , let us adorn your hands with stunning Mehndi designs!
                            </p>
                            <p className='laser-para'>

                                Please <span><a style={{ color: 'GrayText' }} href="/contactus">Contact Us</a></span> today for more information.
                            </p>

                        </Col>


                    </Row>
                </Container>
                <Container>
                    {/* Third Section */}
                    {/* <CardGroup className="my-card-deck">
                        <Card className='custom-card' style={{ backgroundColor: 'mistyrose' }}>
                            <Card.Img className='custom-round' variant="top" src={require("../images/cards/laser-hair-removal-face-768x512.jpg")} />

                            <Card.Body>
                                <Card.Title>Laser Hair Removal</Card.Title>
                                <Card.Text>
                                    Laser hair removal on the face uses laser light technology beamed to the hair follicles to stop hair growth. Laser hair removal requires no downtime, and the procedure itself is quick and takes less than 30 minutes for the face.
                                </Card.Text>
                            </Card.Body>
                            <small className="text-muted text-center custom-service-footer">
                                <Button className="override-button">Laser Hair Removal</Button> {' '}
                            </small>
                        </Card>
                        <Card className='custom-card'  >
                            <Card.Img variant="top" className='custom-round' src={require("../images/cards/facial-768x512.jpg")} />
                            <Card.Body>
                                <Card.Title>Facial</Card.Title>
                                <Card.Text>
                                    A facial is a skin treatment that cleanses pores, exfoliates away dead skin cells, and treats common skin concerns with a custom mask. Rejuvenate and nourish your skin, making skin appear healthier and younger.  We custom each one of our many facials to your skin type and area of concern.{' '}
                                </Card.Text>
                            </Card.Body>
                            <small className="text-muted text-center custom-service-footer">
                                <Button className="override-button">Facial</Button> {' '}
                            </small>
                        </Card>
                        <Card className='custom-card' style={{ backgroundColor: 'mistyrose' }}  >
                            <Card.Img className='custom-round' variant="top" src={require("../images/cards/mehndi-design-768x512.jpg")} />
                            <Card.Body>
                                <Card.Title>Mehndi Design</Card.Title>
                                <Card.Text>
                                    Henna or mehndi, are created with a temporary dye paste made from the crushed leaves and twigs of the henna plant. Check out our gallery of henna/mehndi for a  sampling of the different designs of mehndi offered by us.
                                </Card.Text>
                            </Card.Body>
                            <small className="text-muted text-center custom-service-footer">
                                <Button className="override-button">Mehndi
                                </Button> {' '}
                            </small>
                        </Card>
                    </CardGroup> */}
                </Container>
            </div>
            <Footer />
        </div >
    );
}

export default Services;