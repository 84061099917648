import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';

import { Container } from 'react-bootstrap';
import NavBar from './components/NavBar';
import Services from './pages/Services';
import GalleryPage from './pages/GalleryPage'
import GalleryFacial from './components/GalleryFacial';
import GalleryHairTreat from './components/GalleryHairTreat';
import GalleryMehndi from './components/GalleryMehndi';
import GalleryLaser from './components/GalleryLaser';

import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import GalleryThreading from './components/GalleryThreading';
import GalleryMakeUp from './components/GalleryMakeUp';

function App() {
  return (

    <div fluid style={{ padding: 0}}>
      <Router>
        <Routes>
        <Route path="/" element={<HomePage />}   />
        <Route path="/services" element={<Services />}   />
        <Route path="/gallery" element={<GalleryPage />}   />
        <Route path="/gallery/laser" element={<GalleryLaser />}   />
        <Route path="/gallery/facial" element={<GalleryFacial />}   />
        <Route path="/gallery/haircare" element={<GalleryHairTreat />}   />
        <Route path="/gallery/mehndi" element={<GalleryMehndi />}   />
        <Route path="/gallery/threading" element={<GalleryThreading />}   />
        <Route path="/gallery/makeup" element={<GalleryMakeUp />}   />

        <Route path="/aboutus" element={<AboutUs />}   />
        <Route path="/contactus" element={<ContactUs />}   />



        <Route path="*" element={<HomePage />} />
        </Routes>
      </Router>
    </div>


    // <div className="App">
    //   <NavBar/>
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;
