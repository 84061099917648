import { Container, Carousel, Card, CardGroup, Button, Image, Row, Col } from 'react-bootstrap';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import '../css/services.css';
import '../css/aboutus.css';

function AboutUs() {
    return (
        <div fluid style={{ backgroundColor: '#fff5e9' }}>
            <NavBar className="sticky-top" sticky="top" style={{ position: "sticky" }}></NavBar>
            {/* First Section of carousel */}
            <Container fluid style={{ padding: 0, backgroundColor:'cyan' }}  >
                <Carousel fade controls={false} indicators={false} >
                    <Carousel.Item>
                        <img
                            className="d-block w-100  "
                            src={require("../images/carousel/abtus-carousel.jpg")}
                            alt="First slide"
                        
                        />
                        <Carousel.Caption style={{ top: '80%', transform: 'translateY(-50%)', bottom: 'initial' }}>
                            {/* <header className='animted-header  '>
                           */
                           <h1 
                           style={{  fontFamily:'fantasy', color: "#ff3c5e" }}>
                            About Us
                            </h1>
/*
                            </header> */}
                            {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </Container>
            <Container fluid className="dark-bold section-wrapper info-container background-width--full-bleed section-height--custom" style={{ minHeight: '10vh', paddingTop: '28px' }}>
  
        <Row className="justify-content-center align-items-center laser-para" style={{ height: '100%', marginLeft: '5px', marginRight: '5px' }}>
          
          <Col lg={8} className="text-left text-lg-right">
            <h1 style={{color:'#1792a3'}}>Inner Beauty ans spa</h1>
            <p>Inner Beauty Inner Beauty & Spa is a luxurious salon located in Brampton, Ontario. Our salon features a serene and tranquil environment where you can relax and rejuvenate your mind, body and soul. We offer a wide range of services to fulfill all of your beauty and wellness needs including laser treatments, facials, body treatments, waxing, threading, and nail services.
            </p>
            <p> Our experienced team of professionals is dedicated to providing you with the highest quality of service and personalized attention to ensure your complete satisfaction. We use only the best products and techniques to help you achieve your desired results.
            </p>
            <p> At Inner Beauty Inner Beauty & Spa, we believe that everyone deserves to feel beautiful and pampered. That's why we offer affordable prices and special packages to help you make the most of your salon experience. We also offer gift certificates that make the perfect gift for your loved ones.
            </p>
            <p>  Come visit us at 25 Blue Bonnet Dr, Brampton, ON L6Y 4N4 and let us help you look and feel your best. Call us today on <a href='tel:6477857194' style={{ color: 'black', textDecoration: 'none' }}>+1(647)785-7194</a> / <a style={{ color: 'black', textDecoration: 'none' }} href='tel:6474734646'>+1(647)473-4646</a> to book your appointment and start your journey to inner beauty and wellness!
            </p>
          </Col>
          <Col lg={4} className="text-center">
            <Image className="custom-card-about" src={require("../images/building/laser-bed-240x160.jpg")} alt="Beauty Salon" fluid />
            <Image className="custom-card-about"  src={require("../images/building/hair-chair-240x160.jpg")} alt="Beauty Salon" fluid />
            <Image className="custom-card-about"  src={require("../images/building/laser-bed-2-240x160.jpg")} alt="Beauty Salon" fluid />

          </Col>
        </Row>
      </Container>


            <Footer />
        </div >
    );
}

export default AboutUs;