import { Container, CardGroup, Card, Button, Carousel } from 'react-bootstrap';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import Gallery from '../components/Gallery';
import '../css/homepage.css';

const  GalleryPage = () => {
  return (
    <div fluid style={{ backgroundColor: '#fff5e9' }} >
      <NavBar   className="sticky-top" sticky="top" style={{ position: "sticky"}}></NavBar>
      <Container fluid style={{ padding: 0, backgroundColor:'cyan' }}  >
                <Carousel fade controls={false} indicators={false} >
                    <Carousel.Item>
                        <img
                            className="d-block w-100  "
                            src={require("../images/carousel/abtus-carousel.jpg")}
                            alt="First slide"
                        
                        />
                        <Carousel.Caption style={{ top: '80%', transform: 'translateY(-50%)', bottom: 'initial' }}>
                           
                           <h1 
                           style={{  fontFamily:'fantasy', color: "#ff3c5e" }}>
                            Gallery
                            </h1>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </Container>
      <div className="my-container">
        <Container>
          <CardGroup className="my-card-deck">
            <Card className='custom-card'>
            <a href="/gallery/laser" className='text-center '>
              <Card.Img className='custom-round' variant="top" src={require("../images/cards/laser-hair-removal-face-768x512.jpg")} />
              <small className="text-muted text-center">
               <Button className="override-button  custom-footer">Laser Hair Removal</Button> {' '}
             </small>
            </a> 
            </Card>

            <Card className='custom-card'>
            <a href="/gallery/facial" className='text-center '>
              <Card.Img variant="top" className='custom-round' src={require("../images/cards/facial-768x512.jpg")} />
              <small className="text-muted text-center">
                <Button className="override-button custom-footer">Facial</Button> {' '}</small>
                </a> 
            </Card>

            <Card className='custom-card'   >
            <a href="/gallery/haircare" className='text-center '>

              <Card.Img className='custom-round' variant="top" src={require("../images/cards/hair-treatment-768x512.jpg")} />
              <small className="text-muted text-center">
                <Button className="override-button  custom-footer">Hair Care</Button> {' '}</small>
                </a> </Card>
          </CardGroup>

          <CardGroup className="my-card-deck" style={{marginTop:'15px'}}>
            <Card className='custom-card' >
            <a href="/gallery/threading/" className='text-center '>

              <Card.Img className='custom-round' variant="top" src={require("../images/cards/threading-768x512.jpg")} />
              <small className="text-muted text-center">
                <Button className="override-button  custom-footer">Threading</Button> {' '}</small>
                </a> 
            </Card>
            <Card className='custom-card'>
            <a href="/gallery/makeup/" className='text-center '>
             
              <Card.Img variant="top" className='custom-round' src={require("../images/cards/bridal-makeup-768x512.jpg")} />
              <small className="text-muted text-center ">
                <Button className="override-button  custom-footer">Makeup</Button> {' '}</small>
                </a> 
            </Card>
            <Card className='custom-card'   >
            <a href="/gallery/mehndi" className='text-center '>

              <Card.Img className='custom-round' variant="top" src={require("../images/cards/mehndi-design-768x512.jpg")} />
              <small className="text-muted text-center">
                <Button className="override-button  custom-footer">Mehndi Design</Button> {' '}</small>
                </a> 
            </Card>
          </CardGroup>
        </Container>
      </div>  
      <Footer/>
    </div >
  );
}

export default GalleryPage;