import React from 'react';
import { Container, Row, Col, Button, Carousel, Card, CardGroup, Image } from 'react-bootstrap';
import '../css/homepage.css';
import { Link } from 'react-router-dom';

function HomePageComponent() {
  return (
    <Container fluid style={{ padding: 0 }} >
      <Carousel fade>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={require("../images/carousel/1.jpg")}
            alt="First slide"
          />
          {/* <Carousel.Caption>
            <h3>First slide label</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={require("../images/carousel/2.jpg")}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={require("../images/carousel/3.jpg")}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={require("../images/carousel/4.jpg")}
            alt="Fourth slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={require("../images/carousel/5.jpg")}
            alt="Fifth slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={require("../images/carousel/6.jpg")}
            alt="Sixth slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={require("../images/carousel/7.jpg")}
            alt="Seventh slide"
          />
        </Carousel.Item>

      </Carousel>


      <Container fluid className="dark-bold section-wrapper info-container background-width--full-bleed section-height--custom" style={{ minHeight: '10vh', paddingTop: '22px' }}>
        <Row className="justify-content-center align-items-center" style={{ height: '100%' }}>
          <Col lg={4} className="text-center">
            <span style={{ fontFamily: 'fantasy', fontSize: '56px' }}>Welcome to</span>
            <p style={{ fontFamily: 'fantasy', fontSize: '50px', color: "#ff3c5e" }}> Inner Brauty and Spa</p>

          </Col>
        </Row>
        <Row className="justify-content-center align-items-center laser-para" style={{ height: '100%', marginLeft: '5px', marginRight: '5px' }}>
          <Col lg={4} className="text-center rounded-circle">
            <Image   roundedCircle thumbnail src={require("../images/cards/small-768x512.jpg")} alt="Beauty Salon" fluid />
          </Col>
          <Col lg={8} className="text-left text-lg-right">
          <p>Inner Beauty Inner Beauty & Spa is a luxurious salon located in Brampton, Ontario. Our salon features a serene and tranquil environment where you can relax and rejuvenate your mind, body and soul. We offer a wide range of services to fulfill all of your beauty and wellness needs including laser treatments, facials, body treatments, waxing, threading, and nail services.
            </p>
            <p> Our experienced team of professionals is dedicated to providing you with the highest quality of service and personalized attention to ensure your complete satisfaction. We use only the best products and techniques to help you achieve your desired results.
            </p>
            <p> At Inner Beauty Inner Beauty & Spa, we believe that everyone deserves to feel beautiful and pampered. That's why we offer affordable prices and special packages to help you make the most of your salon experience. We also offer gift certificates that make the perfect gift for your loved ones.
            </p>
            <p>  Come visit us at 25 Blue Bonnet Dr, Brampton, ON L6Y 4N4 and let us help you look and feel your best. Call us today on <a href='tel:6477857194' style={{ color: 'black', textDecoration: 'none' }}>+1(647)785-7194</a> / <a style={{ color: 'black', textDecoration: 'none' }} href='tel:6474734646'>+1(647)473-4646</a> to book your appointment and start your journey to inner beauty and wellness!
            </p>
          </Col>
        </Row>
      </Container>


      {/* Let's see for next section */}
      <div className="my-container">
        <Container>
          <CardGroup className="my-card-deck">
            <Card className='custom-card'>
            <a href="/services/" className='text-center '>
              <Card.Img className='custom-round' variant="top" src={require("../images/cards/laser-hair-removal-face-768x512.jpg")} />
              <small className="text-muted text-center">
               <Button className="override-button  custom-footer">Laser Hair Removal</Button> {' '}
             </small>
            </a> 
            </Card>

            <Card className='custom-card'>
            <a href="/services/" className='text-center '>
              <Card.Img variant="top" className='custom-round' src={require("../images/cards/facial-768x512.jpg")} />
              <small className="text-muted text-center">
                <Button className="override-button custom-footer">Facial</Button> {' '}</small>
                </a> 
            </Card>

            <Card className='custom-card'   >
            <a href="/services/" className='text-center '>

              <Card.Img className='custom-round' variant="top" src={require("../images/cards/hair-treatment-768x512.jpg")} />
              <small className="text-muted text-center">
                <Button className="override-button  custom-footer">Hair Care</Button> {' '}</small>
                </a> </Card>
          </CardGroup>

          <CardGroup className="my-card-deck" style={{marginTop:'15px'}}>
            <Card className='custom-card'>
            <a href="/services/" className='text-center '>

              <Card.Img className='custom-round' variant="top" src={require("../images/cards/threading-768x512.jpg")} />
              <small className="text-muted text-center">
                <Button className="override-button  custom-footer">Threading</Button> {' '}</small>
                </a> 
            </Card>
            <Card className='custom-card'>
            <a href="/services/" className='text-center '>
             
              <Card.Img variant="top" className='custom-round' src={require("../images/cards/bridal-makeup-768x512.jpg")} />
              <small className="text-muted text-center ">
                <Button className="override-button  custom-footer">Makeup</Button> {' '}</small>
                </a> 
            </Card>
            <Card className='custom-card'   >
            <a href="/services/" className='text-center '>

              <Card.Img className='custom-round' variant="top" src={require("../images/cards/mehndi-design-768x512.jpg")} />
              <small className="text-muted text-center">
                <Button className="override-button  custom-footer">Mehndi Design</Button> {' '}</small>
                </a> 
            </Card>
          </CardGroup>
        </Container>
      </div>
    </Container>
  );
}

export default HomePageComponent;


// ///DUMP
// <CardGroup className="my-card-deck">
// <Card>
//   <Card.Img variant="top" src={require("../images/cards/laser-hair-removal-face-768x512.jpg")} />
//   <Card.Body>
//     <Card.Title>Laser Hair Removal</Card.Title>
//     <Card.Text>
//       Laser hair removal on the face uses laser light technology beamed to the hair follicles to stop hair growth. Laser hair removal requires no downtime, and the procedure itself is quick and takes less than 30 minutes for the face.
//     </Card.Text>
//   </Card.Body>
//   <Card.Footer className="text-center">
//     <small className="text-muted"><Button variant="outline-success">Click Here</Button>{' '}</small>
//   </Card.Footer>
// </Card>
// <Card>
//   <Card.Img variant="top" src={require("../images/cards/facial-768x512.jpg")} />
//   <Card.Body>
//     <Card.Title>Facial</Card.Title>
//     <Card.Text>
//       A facial is a skin treatment that cleanses pores, exfoliates away dead skin cells, and treats common skin concerns with a custom mask. Rejuvenate and nourish your skin, making skin appear healthier and younger.  We custom each one of our many facials to your skin type and area of concern.{' '}
//     </Card.Text>
//   </Card.Body>
//   <Card.Footer className="text-center">
//     <small className="text-muted"><Button variant="outline-success">Click Here</Button>{' '}</small>
//   </Card.Footer>
// </Card>
// <Card>
//   <Card.Img variant="top" src={require("../images/cards/mehndi-design-768x512.jpg")} />
//   <Card.Body>
//     <Card.Title>Mehndi Design</Card.Title>
//     <Card.Text>
//       Henna or mehndi, are created with a temporary dye paste made from the crushed leaves and twigs of the henna plant. Check out our gallery of henna/mehndi for a  sampling of the different designs of mehndi offered by us.
//     </Card.Text>
//   </Card.Body>
//   <Card.Footer className="text-center">
//     <small className="text-muted"><Button variant="outline-success">Click Here</Button>{' '}</small>
//   </Card.Footer>
// </Card>
// </CardGroup>
