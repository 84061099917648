import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { FaMapMarkerAlt, FaPhone, FaEnvelope, FaInstagram, FaFacebookF, FaTiktok, FaLinkedinIn } from 'react-icons/fa';
import '../css/footer.css';
function Footer() {
    return (
        <footer className="bg-dark text-white section-55 footer" style={{ marginTop: '15px', paddingTop: '50px', paddingBottom:'30px' }}>
            <Container>
                <Row>
                    <Col md={3}>
                        <a href="/">
                            <Image
                                src={require("../images/logo-150x90.png")} alt="Inner Beauty and Spa" className="img-responsive" />
                        </a>
                        <br />
                        <br />
                        <p>Copyright 2023 ©
                            <a href="/" style={{ color: "white", textDecoration: 'none' }}>Inner Beauty and Spa</a>.</p>
                        <p>Designed by
                            <a href="https://paraspatel.ca" style={{ color: 'white', textDecoration: 'none' }}> Paras Patel</a>.</p>
                    </Col>
                    <Col md={3} className="section-top-23">
                        <h3 className="text-white">Quick Links</h3>
                        <ul className="list-unstyled">
                            <li><a style={{ color: "white", textDecoration: 'none' }} href="/">Home</a></li>
                            <li><a style={{ color: "white", textDecoration: 'none' }} href="/services">Services</a></li>
                            <li><a style={{ color: "white", textDecoration: 'none' }} href="/gallery">Gallery</a></li>
                            <li><a style={{ color: "white", textDecoration: 'none' }} href="/aboutus">About Us</a></li>
                            <li><a style={{ color: "white", textDecoration: 'none' }} href="/contactus">Contact Us</a></li>
                        </ul>
                    </Col>
                    <Col md={3} className="section-top-23">
                        <h3 className="text-white">Contact Us</h3>
                
                        <p>
                            <a style={{ color: "white", textDecoration: 'none' }} href='tel:6477857194'><FaPhone /> +1(647) 785-7194 </a> 
                        </p>
                        <p>
                        <a style={{ color: "white", textDecoration: 'none' }} href='tel:6474734646'> <FaPhone /> +1(647) 473-4646 </a>

                        </p>
                        <p><FaEnvelope /> <a style={{ color: "white", textDecoration: 'none' }} href="mailto:sweetsnahal@gmail.com">sweetsnahal@gmail.com</a></p>
                        <div className="d-flex">
                            <a style={{ color: "white", textDecoration: 'none' }} className='social-icon' href="https://www.instagram.com/innerbeautyandspa/"><FaInstagram className="mr-3" /></a>
                            <a style={{ color: "white", textDecoration: 'none' }} className='social-icon' href="#"><FaFacebookF className="mr-3" /></a>
                            <a style={{ color: "white", textDecoration: 'none' }} className='social-icon' href="https://www.tiktok.com/@sheetalsnehal2"><FaTiktok className="mr-3" /></a>
                            <a style={{ color: "white", textDecoration: 'none' }} className='social-icon' href="#"><FaLinkedinIn /></a>
                        </div>
                    </Col>
                    <Col md={3} className="section-top-23">
                        <h3 className="text-white">Location</h3>
                        <p><FaMapMarkerAlt /> 25 Blue Bonnet Dr, Brampton, ON L6Y 4N4</p>
                        <div >
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2887.2075130585963!2d-79.75214942407764!3d43.643850771102606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b15154da9ff5d%3A0x987f6f1104d988bd!2sInner%20Beauty%20%26%20Spa%20Inc.!5e0!3m2!1sen!2sca!4v1683838021262!5m2!1sen!2sca"
               className="custom-card-map map-half"
             allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;
