import { Container, Carousel, Card, CardGroup, Button, Image, Row, Col } from 'react-bootstrap';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

import '../css/services.css';
import '../css/aboutus.css';
import { FaEnvelope } from 'react-icons/fa';

function ContactUs() {
    return (
        <div fluid style={{ backgroundColor: '#fff5e9' }}>
            <NavBar className="sticky-top" sticky="top" style={{ position: "sticky" }}></NavBar>
            {/* First Section of carousel */}
            <Container fluid style={{ padding: 0, backgroundColor:'cyan' }}  >
                <Carousel fade controls={false} indicators={false} >
                    <Carousel.Item>
                        <img
                            className="d-block w-100  "
                            src={require("../images/carousel/abtus-carousel.jpg")}
                            alt="First slide"
                        
                        />
                        <Carousel.Caption style={{ top: '80%', transform: 'translateY(-50%)', bottom: 'initial' }}>
                            {/* <header className='animted-header  '>
                           */
                           <h1 
                           style={{  fontFamily:'fantasy', color: "#ff3c5e" }}>
                            Contact Us
                            </h1>
/*
                            </header> */}
                            {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </Container>
            <Container fluid className="dark-bold section-wrapper info-container background-width--full-bleed section-height--custom" style={{ minHeight: '10vh', paddingTop: '28px' }}>
  
        <Row className="justify-content-center align-items-center laser-para" style={{ height: '100%', marginLeft: '5px', marginRight: '5px' }}>
          
          <Col lg={8} className="text-left text-lg-right">
            <h1 style={{color:'#1792a3'}}>Inner Beauty ans spa</h1>
            <p>Currently our online contact/ appointment service is under maintanance.</p>
            <p>To book your appointment or fore more information please call us on
            <a href='tel:6477857194' style={{ color: 'black', textDecoration: 'none' }}>+1(647)785-7194</a> / <a style={{ color: 'black', textDecoration: 'none' }} href='tel:6474734646'>+1(647)473-4646</a>.
            </p>
                        <p>Or email us at  <a style={{ color: "black", textDecoration: 'none' }} href="mailto:sweetsnahal@gmail.com"><FaEnvelope /> sweetsnahal@gmail.com</a></p>
            
            <p>we are located at 25 Blue Bonnet Dr, Brampton, ON L6Y 4N4, Come visit us at and let us help 
                you look and feel your best. Start your journey to inner beauty and wellness!
            </p>
            <div className="custom-card-map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2887.2075130585963!2d-79.75214942407764!3d43.643850771102606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b15154da9ff5d%3A0x987f6f1104d988bd!2sInner%20Beauty%20%26%20Spa%20Inc.!5e0!3m2!1sen!2sca!4v1683838021262!5m2!1sen!2sca"
               className="custom-card-map"
             allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </Col>
          <Col lg={4} className="text-center">
            <Image className="custom-card-about" src={require("../images/building/laser-bed-240x160.jpg")} alt="Beauty Salon" fluid />
            <Image className="custom-card-about"  src={require("../images/building/hair-chair-240x160.jpg")} alt="Beauty Salon" fluid />
            <Image className="custom-card-about"  src={require("../images/building/laser-bed-2-240x160.jpg")} alt="Beauty Salon" fluid />

          </Col>
        </Row>
      </Container>


            <Footer />
        </div >
    );
}

export default ContactUs;